import React from 'react'
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactFormNetlify = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <form
        className="flex flex-col w-full mx-auto mt-10 space-y-4 md:w-2/3"
        name="contact"
        method="post"
        action="/contact/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="field">
          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor={'name'}
          >
            Nom
          </label>
          <div className="control">
            <input
              className="contact-field"
              type={'text'}
              name={'name'}
              onChange={this.handleChange}
              id={'name'}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor={'email'}
          >
            Email
          </label>
          <div className="control">
            <input
              className="contact-field"
              type={'email'}
              name={'email'}
              onChange={this.handleChange}
              id={'email'}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor={'subject'}
          >
            Sujet
          </label>
          <div className="control">
            <input
              className="contact-field"
              type={'text'}
              name={'subject'}
              onChange={this.handleChange}
              id={'subject'}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor={'message'}
          >
            Message
          </label>
          <div className="control">
            <textarea
              className="contact-field"
              name={'message'}
              onChange={this.handleChange}
              id={'message'}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <button
            className="px-4 py-2 text-sm font-bold text-white bg-purple-900 rounded shadow-lg hover:bg-purple-700"
            type="submit"
          >
            Envoyer
          </button>
        </div>
      </form>
    )
  }
}

export default ContactFormNetlify
